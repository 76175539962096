import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { FormDialog, Form, WarningIcon, CloseIcon } from "../../../../components";
import { ArcActions, AppActions } from "../../../../store";
import { useNavigate } from 'react-router-dom';
import { CloseCircleOutlined, FolderOpenFilled } from "@ant-design/icons";

const Fields = [
    { label: '', attribute: 'blueprint_id', placeholder: "x000CMP", type: 'text', autocomplete: "off", prefix: "!" },
]
export const ArchiveDialog = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [state, setState] = useState({ formData: { blueprint_id: '' }, error: {} })

    const validateForm = () => {
        let message;
        const { blueprint_id } = state.formData
        if (blueprint_id !== props.blueprint_id) {
            message = 'Invalid Blueprint ID';
        }
        if (message) {
            dispatch(AppActions.setAlert({ success: false, message: message }))
        }
        return message
    }
    const handleValueChange = (e) => {
        const { error } = state, { name, value } = e.target;
        const formData = { ...state.formData }
        formData[name] = value;
        error[name] = ''
        setState((_) => ({ ..._, formData, error: { ...error } }))
    }
    const handleArchive = async (e) => {
        let msg = validateForm();
        if (!msg) {
            dispatch(ArcActions.archiveBlueprint({ task_blueprint_id: props.task_blueprint_id, status: 'archived' }))
        }
        props.onClose && props.onClose(true)
        navigate("/A?tab=active")
    }
    const handleUnArchive = () => {
        dispatch(ArcActions.archiveBlueprint({ task_blueprint_id: props.task_blueprint_id, status: 'active' }))
        props.onClose && props.onClose(true)
        navigate("/A?tab=active")
    }
    return (
        <>
            {
                (props.isArchive) ?
                    <FormDialog
                        TitleComponent={() => {
                            return (
                                <div className='row title-sec'>
                                    <h6 className={`f19 exo2 bolder`}>Confirm Unarchival!</h6>
                                </div>
                            )
                        }}
                        onClose={props.onClose.bind(null, false)}
                        className={`create-doc archive-docs`}
                        dialogClassName={`archive-dialog-width`}
                        rightBtn={{
                            label: (
                                <>
                                    <CloseIcon className="suffix-icon thick-icon" />
                                    Cancel
                                </>
                            ), color: '#0133CC', font: 'f14', className:'exo2 bolder', onClick: props.onClose.bind(null, false),
                        }}
                        leftBtn={{
                            label: (
                                <>
                                    <FolderOpenFilled className="mr1" />
                                    Unarchive</>
                            ), variant: 'lite',font: 'f14', color: '#0133CC', className:'exo2 bolder',  onClick: handleUnArchive,
                            disabled: false
                        }}>
                        <div className='col content'>
                            <p className='f16 c000 warn'>
                                <React.Fragment>
                                    <b>{(props.title) ? props.title : 'Blueprint'}</b> will be unarchived. This action is <b className='text-decor'>NOT</b> reversible.<br />
                                    This action will move the Blueprint to Drafts.
                                </React.Fragment>
                            </p>
                        </div>
                    </FormDialog>
                    :
                    <FormDialog
                        TitleComponent={() => {
                            return (
                                <div className='row title-sec'>
                                    <h6 className={`f19 exo2 bolder`}>Archive Compliance Blueprint?</h6>
                                </div>
                            )
                        }}
                        onClose={props.onClose.bind(null, false)}
                        className={`create-doc archive-docs`}
                        dialogClassName={`archive-dialog-width`}
                        rightBtn={{
                            label: (
                                <>
                                    <CloseIcon className="suffix-icon thick-icon" />
                                    Cancel
                                </>
                            ), color: '#0133CC',font: 'f14', className:'exo2 bolder', onClick: props.onClose.bind(null, false),
                        }}
                        leftBtn={{
                            label: (
                                <>
                                    <FolderOpenFilled className="mr1" />
                                    Archive</>
                            ), variant: 'lite',font: 'f14', className:'exo2 bolder',  color: '#FE3333', onClick: handleArchive,
                            disabled: false
                        }}>
                        <div className='col content'>
                            <p className='f16 c000 warn'>
                                <React.Fragment>
                                    <WarningIcon className='small-warning-icon' />
                                    This action will archive this Blueprint.<br />
                                    <br />
                                    Type the Blueprint ID below to confirm this action.<br />
                                    To stop this action press Cancel.
                                </React.Fragment>
                            </p>
                            <Form
                                fieldClass='form-field'
                                formData={state.formData}
                                error={state.error}
                                Fields={Fields}
                                onChange={handleValueChange} />
                        </div>
                    </FormDialog>
            }
        </>
    )
}