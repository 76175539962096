import { createSlice } from "@reduxjs/toolkit";
import { ReducerRegistry } from "../app";

const StatusList = [
    { id: 'active', label: 'Active' },
    { id: 'archive', label: 'Archived' }
]
const initialState = {
    activeTaskBlueprints: [],
    archivedTaskBlueprints: [],
    StatusList,
    blueprintData: null,
    csvFileStatusResponse: null,
    deleteInProgress: false,
    activeOrgList: null,
    allocatedOrgList: [],
    allocationStatus: {},
    fileUploadDetails: {}
}
const ArcSlice = createSlice({
    name: "Arc",
    initialState: initialState,
    reducers: {
        createTaskBlueprint: () => { },
        fetchTaskBlueprints: () => { },
        fetchTaskBlueprint: () => { },
        editTaskBlueprint: () => { },
        archiveBlueprint: () => { },
        allocateBlueprint: () => { },
        fetchActiveOrgList: () => { },
        setBlueprints: (state, action) => {
            const { active, archive } = action.payload
            if (active) {
                state.activeTaskBlueprints = active
            }
            if (archive) {
                state.archivedTaskBlueprints = archive
            }
        },
        setBlueprintData: (state, action) => {
            const { blueprint } = action.payload;
            state.blueprintData = blueprint
        },
        removeBlueprint: (state, action) => {
            const { blueprint_id } = action.payload;
            let activeBlueprints = Array.isArray(state.activeTaskBlueprints) ? [...state.activeTaskBlueprints] : []
            let archiveBlueprints = Array.isArray(state.archivedTaskBlueprints) ? [...state.archivedTaskBlueprints] : [];
            [activeBlueprints, archiveBlueprints].forEach(list => {
                const index = state[list].findIndex(blueprint => blueprint.blueprint_id === blueprint_id);
                if (index > -1) {
                    state[list].splice(index, 1);
                }
            });
        },
        setCsvFileStatusResponse: (state, action) => {
            state.csvFileStatusResponse = action.payload
        },
        setDeleteInProgress: (state, action) => {
            state.deleteInProgress = action.payload
        },
        setActiveOrgList: (state, action) => {
            state.activeOrgList = action.payload
        },
        setAllocatedOrgList: (state, action) => {
            state.allocatedOrgList = action.payload
        },
        setAllocationStatus: (state, action) => {
            state.allocationStatus = {
                ...state.allocationStatus,
                ...action.payload
            };
        },
        setFileUploadDetails: (state, action) => {
            state.fileUploadDetails = action.payload;
        }
    }
})
export const ArcActions = ArcSlice.actions;
ReducerRegistry.register("Arc", ArcSlice.reducer)
