import { put, takeEvery, select } from "redux-saga/effects";
import { SagaRegistry, setAlertAndLoading, axios, requestHandler, getDateInFormat, getUserDetails } from "../app";
import { GuideActions } from "./slice";
import { getDepartments, dateSorter } from "../common";

const mapGuideContent = (departments, _) => {
  const guide_content = { ..._, more: 'Info', key: _.id };
  guide_content.updated_at = getDateInFormat(guide_content.updated_at)
  guide_content.created_at = getDateInFormat(guide_content.created_at)
  getUserDetails(guide_content, 'creator');
  getUserDetails(guide_content, 'updater');
  if (guide_content.department_name) {
    const dept = departments.find((_) => _.id === guide_content.department_name);
    if (dept) guide_content.department_name = dept;
  }
  return guide_content
}
function* createCentralGuide({ payload }) {
  yield requestHandler(function* () {
    const body = payload
    yield axios.post('/guides', body, axios.getFormDataContentType());
    yield setAlertAndLoading(null, { message: 'Guide Created Successfully' })
    yield put(GuideActions.fetchCentralGuides({ status: 'draft' }))
  }, "Failed to Create Guide");
}
function* updateCentralGuide({ payload }) {
  yield requestHandler(function* () {
    const body = payload
    yield axios.put('/guides/update_guide', body, axios.getFormDataContentType())
    yield setAlertAndLoading(null, { message: 'Guide Updated Successfully' })
    yield put(GuideActions.fetchCentralGuides({ status: 'draft' }))
  }, "Failed to Update Guide");
}
function* fetchCentralGuides({ payload }) {
  yield requestHandler(function* () {
    let { status } = payload;
    let path = 'unarchive_guide_list';
    switch (status) {
      case 'active': path = 'active_guide_list'; break;
      case 'draft': path = 'unarchive_guide_list'; break;
      case 'archive': path = 'archive_guide_list'; break;
    }
    let res = yield axios.get('/guides/' + path);
    const departments = yield select(getDepartments);
    res = res.guide_list ? res.guide_list : res;
    res = Array.isArray(res) ? res : [];
    if (status === 'draft' && path === 'unarchive_guide_list') {
      res = res.filter(guide => guide.status === 'draft');
    }
    res.sort(dateSorter.bind(null, 'updated_at'))
    res = res.map((_, i) => {
      const guide = mapGuideContent(departments, _, i);
      guide.link = '/G/' + (guide.id);
      return guide;
    });
    yield put(GuideActions.setGuides({ [status]: res }))
  }, "Failed to Fetch Central Guides List");
}
function* fetchCentralGuide({ payload }) {
  yield requestHandler(function* () {
    let { id } = payload;
    let res = yield axios.get('/guides/show_guide?guide_id=' + id);
    const departments = yield select(getDepartments)
    if (res) {
      const guide_content = mapGuideContent(departments, res)
      yield put(GuideActions.setShowGuide({ guide: guide_content }))
    }
  }, "Failed to fetch Central Guide");
}
function* fetchGuideReviewerList({ payload }) {
  yield requestHandler(function* () {
    const res = yield axios.get('/guides/reviewer_list');
    const list = (res.reviewer_list || []).map((_) => {
      let reviewerName = _[0]
      if (_[2]) {
        reviewerName = reviewerName + ", " + _[2]
      }
      if (_[3]) {
        reviewerName = reviewerName + ", " + _[3]
      }
      return { id: _[1], label: reviewerName }
    })
    yield put(GuideActions.setGuideReviwerList(list))
  }, "Failed to fetch reviewer list");
}

function* sendGuideForReview({ payload }) {
  yield requestHandler(function* () {
    let body = {reviewer_id: payload.email, guide_id: payload.id  }
    yield axios.put('/guides/update_reviewer', body);
    yield setAlertAndLoading(null, { message: 'Guide has been sent for Review' })
  }, "Failed to send for review");
}
function* activeGuide({ payload }) {
  yield requestHandler(function* () {
    const { guide_id, status, message } = payload
    try {
      yield put(GuideActions.setDeleteInProgress(true));
      yield changeGuideStatus({ payload: { guide_id, status: status } });
      yield put(GuideActions.fetchCentralGuides({ status: 'active' }))
      yield setAlertAndLoading(null, { message: message || 'Guide has been activated' });
      yield put(GuideActions.setDeleteInProgress(false));
    } catch (error) {
      console.error("Failed to activate guide:", error);
      yield put(GuideActions.setDeleteInProgress(false));
    }
  }, "Failed to activate guide");
}
function* archiveGuide({ payload }) {
  yield requestHandler(function* () {
    const { guide_id } = payload;
    try {
      yield put(GuideActions.setDeleteInProgress(true));
      yield changeGuideStatus({ payload: { guide_id, status: 'archive' } });
      yield setAlertAndLoading(null, { message: 'Guide has been Archived' });
      yield put(GuideActions.removeGuide(payload));
      yield put(GuideActions.setDeleteInProgress(false));
    } catch (error) {
      console.error("Failed to archive guide:", error);
      yield put(GuideActions.setDeleteInProgress(false));
    }
  }, "Failed to archive guide");
}

function* unarchiveGuide({ payload }) {
  yield requestHandler(function* () {
    const { guide_id, status, message } = payload
    try {
      yield put(GuideActions.setDeleteInProgress(true));
      yield changeGuideStatus({ payload: { guide_id, status: status } });
      // yield axios.get('/guides/unarchive_guide_list')
      yield put(GuideActions.fetchCentralGuides({ status: 'draft' }))
      yield setAlertAndLoading(null, { message: message || 'Guide has been Unarchived' });
      yield put(GuideActions.setDeleteInProgress(false));
    } catch (error) {
      console.error("Failed to unarchive guide:", error);
      yield put(GuideActions.setDeleteInProgress(false));
    }
  }, "Failed to unarchive guide");
}
function* deleteGuide({ payload }) {
  const {guide_id} = payload
  yield requestHandler(function* () {
    try {
      yield axios.delete(`/guides/delete_guide/?guide_id=${guide_id}`);
      yield setAlertAndLoading(null, { message: 'Guide has been Deleted' })
      yield put(GuideActions.removeGuide(payload))
      yield put(GuideActions.setDeleteInProgress(false))
    } catch (error) {
      console.log(error);
      yield put(GuideActions.setDeleteInProgress(false))
    }
  }, "Failed to delete guide");
}
const changeGuideStatus = function* ({ payload }) {
  yield requestHandler(function* () {
    const { guide_id, status, message } = payload
    let body = { status: status, guide_id: guide_id, }
    yield axios.put('/guides/change_guide_status', body)
    yield put(GuideActions.fetchCentralGuide({ guide_id: guide_id }))
    yield setAlertAndLoading(null, { message: message || 'Guide status has been updated' })
  }, "Failed to update guide status");
}
SagaRegistry.register(function* userSaga() {
  yield takeEvery("Guide/createCentralGuide", createCentralGuide);
  yield takeEvery("Guide/fetchCentralGuides", fetchCentralGuides);
  yield takeEvery("Guide/fetchCentralGuide", fetchCentralGuide);
  yield takeEvery("Guide/updateCentralGuide", updateCentralGuide);
  yield takeEvery("Guide/fetchGuideReviewerList", fetchGuideReviewerList);
  yield takeEvery("Guide/sendGuideForReview", sendGuideForReview);
  yield takeEvery("Guide/activeGuide", activeGuide);
  yield takeEvery("Guide/archiveGuide", archiveGuide);
  yield takeEvery("Guide/unarchiveGuide", unarchiveGuide);
  yield takeEvery("Guide/deleteGuide", deleteGuide);
  yield takeEvery("Guide/changeGuideStatus", changeGuideStatus);
})
