import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { OvalLoading, DocumentEditor } from "../../../components";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { docActions, getCurrentOrgDoc, getDepartments, getDocTypes, getCurrentUser } from "../../../store";

export const OrgDocEditorScreen = (props) => {
  const { readOnly } = props;
  const { documentId } = useParams();
  const [state, setState] = useState({ show: false })
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const currentDoc = useSelector(getCurrentOrgDoc)
  const depts = useSelector(getDepartments)
  const types = useSelector(getDocTypes)
  const _docRef = React.useRef(null);
  const user = useSelector(getCurrentUser)
  // const status = useSelector(getDocStatusList)
  useEffect(() => {
    if (Array.isArray(depts) && Array.isArray(types)) {
      dispatch(docActions.fetchOrgDocument({ documentId }))
    }
  }, [depts, types]);
  useEffect(() => {
    if (currentDoc) {
      if (currentDoc === 'NOTFOUND') {
        navigate('/page-not-found')
      } else {
        setState((_) => ({ ..._, show: true }));
      }
    } else {
      setState((_) => ({ ..._, show: false }));
    }
  }, [currentDoc]);
  const getSections = () => {
    return []
  }
  return (
    <div className='col w-100 h-100 o-hide screen-pad new-content doc'>
      {
        state.show &&
        <div className='col w-100 h-100 o-hide doc-editor'>
          {
            Boolean(currentDoc) ?
              <DocumentEditor
                ref={_docRef}
                isSchemaOnly={false}
                user={user}
                sections={getSections()}
                inEditMode={!readOnly}
                classifications={[]}
                document={currentDoc} />
              :
              <OvalLoading />
          }
        </div>
      }
      {
        !state.show &&
        <OvalLoading />
      }
    </div>
  )
}