import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { FormDialog, Form } from "../../../../components";
import { AppActions, GUIDESTATUS, GuideActions } from "../../../../store";

export const ActiveDialog = (props) => {
    const { guide, user, onClose } = props;
    const dispatch = useDispatch()

    const handleActive = async () => {
        const status = GUIDESTATUS.ACTIVE;
        const message = 'Guide has been Activated';
        dispatch(GuideActions.activeGuide({ guide_id: guide.id, status, message }))
        props.onClose && props.onClose(true)
    }
    return (
        <FormDialog
            className={`activate-popup`}
            title={"Activate Guide?"}
            titleClass={`c009900 f6 med`}
            onClose={onClose.bind(null, false)}
            rightBtn={{ label: 'Activate', color: '#009900', font: 'f9', variant: 'lite', onClick: handleActive }}
            leftBtn={{ label: "Cancel", font: 'f9', onClick: onClose.bind(null, false) }}
        >
            <div className='txt-justify f8 c00085'>
                <p>
                    This action will prevent further changes to this Guide.
                    Status will be changed to Active.
                    Reversing this action is <b>NOT POSSIBLE.</b>
                </p>
                <p>
                    To cancel this action click Cancel.
                </p>
            </div>

        </FormDialog>
    )
}