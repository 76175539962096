import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Menu, DocumentTable, Button, LinkButton } from "../../../components";
import { Tabs } from 'antd';
import { ConHistoryDialog } from "./History";
import { ContinuerActions, getContinuerBlueprints, getContinuerDeleteStatus } from "../../../store";

const CommonColumns = [
  { title: 'Version', dataIndex: 'version', key: 'version', width: '7%', className: 'exo2 f9 p3 text-center' },
  { title: 'Scenario ID', dataIndex: 'scenario_id', key: 'scenario_id', width: '11%', className: 'exo2 f9 text-center ' },
  { title: 'Scenario', dataIndex: 'title', key: 'scenario', width: '50%', search: true, render: 'link', className: 'exo2 f9 ' },
  { title: 'Department', dataIndex: 'department', key: 'department', width: '10%', className: 'exo2 f9  ' },
  { title: 'Updated', dataIndex: 'updated_at', key: 'updated_at', width: '10%', className: 'exo2 f9' },
  { title: 'More', dataIndex: 'more', key: 'more', width: '6%', render: 'btn', variant: 'lite', color: '#0033CC', className: 'exo2 f9 text-center' },
]

const getTab = () => {
  let currentUrlParams = new URLSearchParams(window.location.search);
  return currentUrlParams.get('tab') || 'active'
}
export const ContinuerControllerBluePrintScreen = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [state, setState] = useState({ tab: getTab() })
  const blueprints = useSelector(getContinuerBlueprints.bind(null, state.tab))
  const deleteStatus = useSelector(getContinuerDeleteStatus);

  useEffect(() => {
    dispatch(ContinuerActions.setShowBlueprint({ blueprint: null }))
    dispatch(ContinuerActions.setNewBlueprintId(''))
  }, [])
  useEffect(() => {
    dispatch(ContinuerActions.fetchContinuityBlueprints({status: state.tab}))
  }, [state.tab])
  const handleMoreInfo = (blueprint) => {
    dispatch(ContinuerActions.setBlueprintAuditHistory([]))
    dispatch(ContinuerActions.setBlueprintChangeHistory([]))
    dispatch(ContinuerActions.fetchBlueprintAudit({ id: blueprint.id }))
    setState((_) => ({ ..._, showDialog: 'history', selectedDoc: blueprint }))
  }
  const getColumns = () => {
    let columns = [...CommonColumns]
    columns[columns.length - 1] = { ...columns[columns.length - 1], onClick: handleMoreInfo }
    return columns
  }
  return (
    <div className='col w-100 h-100 o-hide screen-pad'>
      <div className='row docs-header h-btn'>
        <h4 className='exo2 f4 med c238787'>Blueprint</h4>
        {state.tab === 'draft' && <LinkButton label="New Blueprint" link="new" font='f9' />}
      </div>
      <div className='col f-rest o-hide'>
        <Tabs
          activeKey={state.tab}
          onChange={(key) => {
            setState((_) => ({ ..._, tab: key }))
            navigate(`${window.location.pathname}?tab=${key}`)
          }}
          items={[{ label: 'Active', key: 'active' }, { label: 'Draft', key: 'draft' }, { label: 'Archive', key: 'archive' }]} />
        <div className='col f-rest o-hide'>
          <DocumentTable
            noSelection
            Columns={getColumns()}
            rows={blueprints}
            emptyMessage={state.tab === 'active' ? 'No Active Blueprints' : state.tab === 'archive' ? 'No Archive Blueprints' : 'No Draft Blueprints'}
            height='h-90'
          />
        </div>
      </div>
      {
        state.showDialog == 'history' &&
        <ConHistoryDialog
          onClose={() => {
            setState((_) => ({ ..._, showDialog: '', selectedDoc: null }))
            dispatch(ContinuerActions.setBlueprintAuditHistory(null))
            dispatch(ContinuerActions.setBlueprintChangeHistory(null))
          }}
          blueprint={state.selectedDoc} />
      }
    </div>
  )
}