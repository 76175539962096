import React from 'react';
import MuiMenu from '@mui/material/Menu';
import { Link } from 'react-router-dom';
import "./style.scss";

const MenuItem = (props) => {
  const { label, icon, iconClass, textClass, color, primary, iconElement, font } = props;
  return (
    <div className='row h-ctr' style={{ width: '100%' }}>
      <div className='icon'>
        {iconElement ? (
          <div className={`${color}`}>
            {iconElement}
          </div>
        ) : (
          <i className={`f8 ${icon} ${iconClass || ''} ${!primary && (color || 'c00085')}`} />
        )}
      </div>
      <span className={`reg ${font || 'f8'} ${textClass || ''} ${!primary && (color || 'c00085')}`}>{label}</span>
    </div>
  );
}

export const Menu = (props) => {
  const { anchorEl, menuItems, width } = props;

  return (
    <React.Fragment>
      <MuiMenu
        anchorEl={anchorEl}
        id="account-menu"
        open={Boolean(anchorEl)}
        onClose={props.onClose}
        onClick={props.onClick}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 0px 2px rgba(0,0,0,0.32))',
            mt: 1.5,
            width,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 5,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <div className='col menus' style={{ width: '100%' }}>
          {
            (menuItems || []).map((item, index) => {
              const isEmpty = Boolean(item.isEmpty);
              const className = `btn row item ${isEmpty ? 'empty' : ''} ${item.primary ? 'primary' : ''}`;
              return (
                item.isLink ?
                  <Link to={item.to} className={className} key={index} style={{ width: '100%' }}>
                    <MenuItem {...item} />
                  </Link> :
                  <button key={index} className={className} disabled={item.disabled} onClick={(e) => !isEmpty && props.onMenuClick(e, item)} style={{ width: '100%' }}>
                    {!isEmpty && <MenuItem {...item} />}
                  </button>
              );
            })
          }
        </div>
      </MuiMenu>
    </React.Fragment>
  );
}
