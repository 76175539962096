import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { FormDialog, Form, WarningIcon } from "../../../components";
import { docActions, isArchived, AppActions } from "../../../store";
const Fields = [
  { label: '', attribute: 'email', placeholder: 'Type Your Email Address', autoFocus: true, autocomplete: "off", type: 'email' },
  { label: '', attribute: 'doc_id', placeholder: "Document ID", type: 'text', autocomplete: "off" },
]
export const ArchiveDeleteDialog = (props) => {
  const { blueprint, isContent, isDelete, user } = props;
  const isArchive = isArchived(blueprint);
  const dispatch = useDispatch()
  const [state, setState] = useState({ formData: { doc_id: '', email: '' }, error: {} })
  const validateForm = () => {
    let message;
    const { doc_id, email } = state.formData;
    if (user.email !== email) {
      message = 'Email address mis match';
    }
    if (isContent && blueprint.doc_id !== doc_id) {
      message = 'Invalid Document ID';
    }
    if (message) {
      dispatch(AppActions.setAlert({ success: false, message: message }))
    }
    return message
  }
  const handleDelete = async (e) => {
    if (isDelete) {
      let msg = validateForm();
      if (!msg) {
        dispatch(docActions.setDeleteInProgress(true))
        dispatch(docActions.deleteDocument({ isContent, id: blueprint.id }))
      }
    } else if (isArchive) {
      const audits = blueprint.audits;
      let prevAudit = '';
      audits.every((audit) => {
        if (
          audit.audited_changes && Array.isArray(audit.audited_changes.status) &&
          (audit.audited_changes.status[1] === 'archived' || audit.audited_changes.status[1] === 'archive')) {
          prevAudit = audit.audited_changes.status[0]
        }
        return !Boolean(prevAudit)
      })
      if (prevAudit) {
        dispatch(docActions.setDeleteInProgress(true))
        dispatch(docActions.unarchiveDocument({ isContent, id: blueprint.id, status: prevAudit }))
      }
    } else {
      let msg = validateForm();
      if (!msg) {
        dispatch(docActions.setDeleteInProgress(true))
        dispatch(docActions.archiveDocument({ id: blueprint.id, isContent }))
      }
    }
    props.onClose && props.onClose(true)
  }
  const handleValueChange = (e) => {
    const { error } = state, { name, value } = e.target;
    const formData = { ...state.formData }
    formData[name] = value;
    error[name] = ''
    setState((_) => ({ ..._, formData, error: { ...error } }))
  }
  return (
    <FormDialog
      TitleComponent={() => {
        return (
          <div className='row title-sec'>
            <WarningIcon className='warning' />
            <h6 className={`f6 exo2 bold`}>{`${isDelete ? 'Delete' : isArchive ? 'Unarchive' : 'Archive'} Document ?`}</h6>
          </div>
        )
      }}
      onClose={props.onClose.bind(null, false)}
      className={`create-doc archive-docs ${isDelete ? 'delete-docs' : ''}`}
      dialogClassName={`archive-dialog-width`}
      leftBtn={{ label: 'Cancel', variant: 'lite', color: '#0133CC', onClick: props.onClose.bind(null, false), }}
      rightBtn={{
        label: isDelete ? 'Delete' : isArchive ? 'Unarchive' : 'Archive', variant: 'lite', color: '#FE3333', onClick: handleDelete,
        disabled: isArchive ? false : (state.formData.email.length === 0 || (isContent && state.formData.doc_id.length === 0))
      }}>
      <div className='col content'>
        <p className='f9 c777 warn'>
          {
            isDelete ?
              <React.Fragment>
                This action will <span className='bold'>permanently & irrecoverably</span> delete the {isContent ? 'Content' : 'Presentation'} blueprint.<br />
                Enter your Email Address {isContent ? 'and DocumentID ' : ''}below to confirm this action.<br />
                To stop this action press Cancel
              </React.Fragment>
              :
              <React.Fragment>
                This action will {isArchive ? 'show' : 'hide'} this {isContent ? 'Content' : 'Presentation'} Blueprint from all views.<br />
                Do you wish to proceed?
              </React.Fragment>
          }
        </p>
        {
          (isDelete || (!isDelete && !isArchive)) &&
          <Form
            fieldClass='field-input'
            formData={state.formData}
            error={state.error}
            Fields={isContent ? Fields : [Fields[0]]}
            onChange={handleValueChange} />
        }
      </div>
    </FormDialog>
  )
}