import "./saga";
export * from "./slice";

const getGuideState = (state) => state.Guide;
export const GUIDESTATUS = {
  APPROVED: 'approved',
  ACTIVE: 'active',
  DELETED: 'deleted',
  ARCHIVED: 'archived',
  DRAFT: 'draft'
}
export const getCentralStatusList = (state) => {
  const _state = getGuideState(state);
  return _state.StatusList
}
export const getCentralGuides = (status, state) => {
  const _state = getGuideState(state);
  return status === 'active' ? _state.activeGuides : status === 'archive' ? _state.archiveGuides : _state.draftGuides;
}
export const getCentralGuide = (guideId, state) => {
  const _state = getGuideState(state),
    finder = (_) => _.id === Number(guideId);
  let guide = _state.activeGuides.find(finder)
  if (!guide) {
    guide = _state.draftGuides.find(finder)
  }
  if (!guide) {
    guide = _state.archiveGuides.find(finder)
  }
  return guide
}
export const getShowCentralGuide = (state) => {
  const _state = getGuideState(state);
  return _state.showGuide
}
export const getCentralArchives = (status, state) => {
  const _state = getGuideState(state);
  return _state.archiveGuides;
}
export const getCentralActives = (status, state) => {
  const _state = getGuideState(state);
  return _state.activeGuides;
}
export const getCentralDrafts = (status, state) => {
  const _state = getGuideState(state);
  return _state.draftGuides;
}
export const getCentralDeleteStatus = (state) => {
  const _state = getGuideState(state);
  return _state.deleteInProgress;
}

export const isGuideArchived = (guide) => guide && (guide.status === 'archive' || guide.status === 'archived')

export const isGuideDraft = (guide) => {
  return guide && (guide.status === 'draft' || guide.status === 'outline')
}
export const isGuideApproved = (guide) => {
  return guide && guide.status === GUIDESTATUS.APPROVED
}
export const isGuideActive = (guide) => {
  return guide && guide.status === GUIDESTATUS.ACTIVE
}
export const getGuideSectionReviewers = (state) => {
  const _state = getGuideState(state);
  return _state.reviewers;
}