import React from 'react';
import { Link } from 'react-router-dom';
import "./style.scss"

const ButtonContainer = (props) => {
  const { className, variant, font, ...rest } = props
  return (
    <button className={`btn line-22 ${font || ''} ${variant === 'lite' ? 'bg-lite' : 'bg0133CC'} ${className || ''}`} {...rest} onClick={props.onClick} >
      {props.children}
    </button>
  )
}
const ButtonContent = (props) => {
  const { label, icon, iconColor, textFont, color, link, iconcomponent, labelClassName, ...rest } = props
  const style = rest.disabled ? {} : { color: rest.variant === 'lite' ? color : '#FFF' }
  return (
    <>
      {Boolean(icon) && <i className={`${icon} icon ${Boolean(label) ? 'icon-text' : ''} ${iconColor || ''}`} />}
      {Boolean(iconcomponent) && (
        <span>{iconcomponent}</span>
      )}
      {Boolean(label) && (
        <span className={`label ${labelClassName}`} style={style}>{label}</span>
      )}
    </>
  )
}
export const Button = (props) => {
  const { label, icon, iconColor, textFont, color, ...rest } = props
  const style = rest.disabled ? {} : { color: rest.variant === 'lite' ? color : '#FFF' }
  return (
    <ButtonContainer {...rest}>
      <ButtonContent {...props} />
    </ButtonContainer>
  )
}
export const LinkButton = (props) => {
  const { href, className, variant, font, link, ...rest } = props
  return (
    Boolean(href) ?
      <a href={href} className={`btn line-22 ${font || ''} ${variant === 'lite' ? 'bg-lite' : 'bg0133CC'} ${className || ''}`} {...rest}>
        <ButtonContent {...props} />
      </a>
      :
      <Link to={link} className={`btn line-22 ${font || ''} ${variant === 'lite' ? 'bg-lite' : 'bg0133CC'} ${className || ''}`}>
        <ButtonContent {...props} />
      </Link>
  )
}
