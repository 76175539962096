import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { FormDialog, Form, WarningIcon } from "../../../../components";
import { isGuideArchived, AppActions, GuideActions } from "../../../../store";

const Fields = [
  { label: '', attribute: 'guide_id', placeholder: "Guide ID", type: 'text', autocomplete: "off" },
]
export const ArchiveDeleteDialog = (props) => {
  const { isDelete, user, guide } = props;
  const isArchive = isGuideArchived(guide);
  const dispatch = useDispatch()
  const [state, setState] = useState({ formData: { guide_id: '' }, error: {} })

  const validateForm = () => {
    let message;
    const { guide_id } = state.formData
    if (guide.guide_id.toString() !== guide_id.toString()) {
      message = 'Invalid Guide ID';
    }
    if (message) {
      dispatch(AppActions.setAlert({ success: false, message: message }))
    }
    return message
  }
  const handleValueChange = (e) => {
    const { error } = state, { name, value } = e.target;
    const formData = { ...state.formData }
    formData[name] = value;
    error[name] = ''
    setState((_) => ({ ..._, formData, error: { ...error } }))
  }
  const handleDelete = async (e) => {
    if (isDelete) {
      let msg = validateForm();
      if (!msg) {
        dispatch(GuideActions.setDeleteInProgress(true))
        dispatch(GuideActions.deleteGuide({ guide_id: guide.id }))
      }
    } else if (isArchive) {
      dispatch(GuideActions.unarchiveGuide({ guide_id: guide.id }))
    } else {
      dispatch(GuideActions.archiveGuide({ guide_id: guide.id }))
    }
    props.onClose && props.onClose(true)

  }
  return (
    <FormDialog
      TitleComponent={() => {
        return (
          <div className='row title-sec'>
            <h6 className={`f6 exo2 bold`}>{`${isDelete ? 'Delete' : isArchive ? 'Unarchive' : 'Archive'} Guide?`}</h6>
          </div>
        )
      }}
      onClose={props.onClose.bind(null, false)}
      className={`create-doc archive-docs`}
      dialogClassName={isArchive? 'guide-archive-dialog-width' : 'guide-delete-dialog-width'}
      leftBtn={{ label: 'Cancel', variant: 'lite', color: '#0133CC', onClick: props.onClose.bind(null, false), }}
      rightBtn={{
        label: isDelete ? 'Delete' : isArchive ? 'Unarchive' : 'Archive', variant: 'lite', color: '#FE3333', onClick: handleDelete,
        disabled: isArchive? false : !(state.formData.guide_id)
      }}>
      <div className='col content'>
        <p className='f9 c000 warn'>
          {
            isDelete ?
              <React.Fragment>
                <WarningIcon className='small-warning-icon' />
                This action is <span className='bold'>permanent & irreversible.</span><br />
                Type the GuideID below to confirm this action.<br/>
                To stop this action press Cancel.
              </React.Fragment>
              :
              <React.Fragment>
                <WarningIcon className='small-warning-icon' />
                This action will archive this Guide.<br />
                To stop this action press Cancel.
              </React.Fragment>
          }
        </p>
        {
          (isDelete) &&
          <Form
            fieldClass='field-input'
            formData={state.formData}
            error={state.error}
            Fields={Fields}
            onChange={handleValueChange} />
        }

      </div>
    </FormDialog>
  )
}