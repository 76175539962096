import { Breadcrumbs, Link as MuiLink } from '@mui/material';
import {axios, AppActions} from '../../../../store';

export const TopBreadCrumb = ({ isActive, isArchive, isDraft, edit, create }) => {
    return (
        <Breadcrumbs aria-label="breadcrumb">
            {
                isActive && <MuiLink underline="hover" href="/G?tab=active"><div className='c0133CC f9'>Active /</div></MuiLink>
            }
            {
                isArchive && <MuiLink underline="hover" color="inherit" href="/G?tab=archive"> <div className='c0133CC f9'>Archive / </div></MuiLink>
            }
            {
                (isDraft || edit || create) && < MuiLink underline="hover" color="inherit" href="/G?tab=draft"><div className='c0133CC f9'>Draft /</div> </MuiLink>
            }

        </Breadcrumbs>
    )
}



export const parseAndSortSectionContent = (sectionContent, sectionIndex) => {
    // sorting the paragraphs, images, videos within the section
    let tempSectionContent = [];
    if (sectionContent.guide_stmts && sectionContent.guide_stmts.length > 0) {
        sectionContent.guide_stmts.forEach((stmt, para_index) => {
            let sectionOrderNo = sectionContent.order_no || (sectionIndex + 1)
            tempSectionContent.push({
                id: stmt.id,
                type: 'paragraph',
                value: stmt.stmt,
                label: 'Paragraph',
                editorRef: 'para_' + sectionOrderNo + '_' + stmt.order_no || 'para_' + sectionOrderNo + '_' + para_index,
                order_no: stmt.order_no
            });
        });
    }
    if (sectionContent.youtube_links && sectionContent.youtube_links.length > 0) {
        sectionContent.youtube_links.forEach(link => {
            tempSectionContent.push({
                id: link.id,
                type: 'youtube_link',
                value: link.link,
                label: 'YouTube Link',
                order_no: link.order_no
            });
        });
    }
    if (sectionContent.attachments && sectionContent.attachments.length > 0) {
        sectionContent.attachments.forEach(att => {
            tempSectionContent.push({
                id: att.id,
                type: 'image',
                value: att.document_file_name,
                label: 'Image',
                url: att.evidence_url.startsWith('//') ? 'https:' + att.evidence_url : att.evidence_url,
                order_no: att.order_no
            });
        });
    }
    tempSectionContent.sort((a, b) => a.order_no - b.order_no);
    return {
        id: sectionContent.id,
        order_no: sectionContent.order_no,
        title: sectionContent.title,
        section_content: tempSectionContent
    };
}


export const sortGuideSections = (sections) => {
    // sort the sections based on order no
    sections.sort((a, b) => a.order_no - b.order_no);
}
export const handleAddSection = (guideData, setGuideData) => {
    const temp = { ...guideData }
    let sectionOrderNo = Array.isArray(temp?.guide_sections) && (temp.guide_sections.length + 1).toString()
    temp.guide_sections.push({
        id: '',
        order_no: sectionOrderNo,
        title: '',
        section_content: [
            { id: '', type: 'paragraph', value: '', editorRef: 'para_' + sectionOrderNo + '_0', label: 'Paragraph' },
            { id: '', type: 'image', value: '', label: 'Image' },
            { id: '', type: 'youtube_link', value: '', label: 'YouTube Link' }
        ]
    })
    setGuideData(temp)
}
export const handleAddInputField = (type, sectionIndex, guideData, setGuideData) => {
    let temp = { ...guideData }
    console.log(temp.guide_sections)
    if (type === 'paragraph') {
        let editorRef = 'para_' + (sectionIndex + 1) + '_' + (Array.isArray(temp?.guide_sections) ? temp.guide_sections[sectionIndex].section_content.length : 1).toString()
        temp.guide_sections[sectionIndex].section_content.push({ id: '', type: 'paragraph', value: '', editorRef: editorRef, label: 'Paragraph' })
    }
    else if (type === 'image') {
        temp.guide_sections[sectionIndex].section_content.push({ id: '', type: 'image', value: '', label: 'Image' })
    }
    else {
        temp.guide_sections[sectionIndex].section_content.push({ id: '', type: 'youtube_link', value: '', label: 'YouTube Link' })
    }
    setGuideData(temp)
}
export const getGuideBody = (guideData, ckRefs) => {
    const body = new FormData();
    const data = { ...guideData };
    body.append(`guide[title]`, data['title'])
    body.append(`guide[module_name]`, data['module_name'])
    data.guide_sections.forEach((section, index) => {
        let baseKey = `guide[guide_details_attributes][${index}]`;
        if (section.id) {
            body.append(`${baseKey}[id]`, section.id)
        }
        body.append(`${baseKey}[order_no]`, section.order_no)
        body.append(`${baseKey}[title]`, section.title)
        section.section_content.forEach((item, itemIndex) => {
            switch (item.type) {
                case 'paragraph':
                    const paragraphData = ckRefs.current[item.editorRef].getData();
                    body.append(`${baseKey}[guide_stmts_attributes][${itemIndex}][stmt]`, paragraphData);
                    body.append(`${baseKey}[guide_stmts_attributes][${itemIndex}][order_no]`, itemIndex);
                    if (item.id) {
                        body.append(`${baseKey}[guide_stmts_attributes][${itemIndex}][id]`, item.id);
                    }
                    break;
                case 'image':
                    if (item.value instanceof File) {
                        body.append(`${baseKey}[attachments_attributes][${itemIndex}][document]`, item.value);
                        body.append(`${baseKey}[attachments_attributes][${itemIndex}][order_no]`, itemIndex);
                        if (item.id) {
                            body.append(`${baseKey}[attachments_attributes][${itemIndex}][id]`, item.id);
                        }
                    }
                    break;
                case 'youtube_link':
                    body.append(`${baseKey}[youtube_links_attributes][${itemIndex}][link]`, item.value);
                    body.append(`${baseKey}[youtube_links_attributes][${itemIndex}][order_no]`, itemIndex);
                    if (item.id) {
                        body.append(`${baseKey}[youtube_links_attributes][${itemIndex}][id]`, item.id);
                    }
                    break;
            }
        })
    })
    return body;
};
export const handleSectionDelete = async (index, id, guideData, setGuideData) => {
    try {
        await axios.post('/guides/delete_guide_detail', { 'id': id })
        let temp = { ...guideData }
        temp['guide_sections'].splice(index, 1)
        setGuideData(temp)
    } catch (error) {
        console.error("Error deleting Section:", error.response ? error.response.data : error.message);
    }
}
export const handleSectionContentDelete = async (index, section_content_index, item, guideData, setGuideData) => {
    try {
        if (item.type === 'youtube_link') {
            await axios.post('/guides/delete_youtube_link', { 'id': item.id });
        }
        else if (item.type === 'image') {
            await axios.post('/guides/delete_attachment', { 'id': item.id });
        }
        else {
            await axios.post('/guides/delete_guide_stmt', { 'id': item.id });
        }
        let temp = { ...guideData };
        temp['guide_sections'][index]['section_content'].splice(section_content_index, 1);
        setGuideData(temp);
    } catch (error) {
        console.error("Error deleting YouTube link:", error.response ? error.response.data : error.message);
    }
};
const extractTextFromHtml = (htmlData) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlData, 'text/html');
    return doc.body.textContent || "";
};
export const enableSave = (guideData, guideNames, edit, ckRefs, dispatch) => {
    let enabled = true;
    if (guideData && guideData.title && guideData.module_name) {
        if (!edit) {
            for (let title of guideNames) {
                if (title === guideData.title) {
                    dispatch(AppActions.setAlert({ success: false, message: 'Duplicate Guide Title is not allowed.' }))
                    enabled = false
                    break;
                }
            }
        }
        for (let section of guideData.guide_sections) {
            if (!(section.order_no) || !(section.title)) {
                enabled = false;
                break;
            }
            for (let content of section.section_content) {
                if (content.type === 'paragraph' && ckRefs.current[content.editorRef]) {
                    let data = ckRefs.current[content.editorRef].getData();
                    let text = extractTextFromHtml(data);
                    if (!text.trim()) {
                        enabled = false;
                        break;
                    }
                }
            }
        }
    } else {
        enabled = false;
    }
    return enabled
}
