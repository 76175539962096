import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { DocumentTable } from "../../../components";
import { docActions, getAllOrgDocuments } from "../../../store";

const DocumentColumns = [
  { title: 'Organization', dataIndex: 'ent_org_name', key: 'ent_org_name', width: '9%', className: 'exo2 f9', sort: true },
  { title: 'Version', dataIndex: 'version', key: 'version', width: '100px', className: 'exo2 f9' },
  { title: 'ID', dataIndex: 'doc_id', key: 'doc_id', width: '100px', className: 'exo2 f9 text-center', sort: true },
  { title: 'Document', dataIndex: 'name', key: 'name', width: '42%', render: "link", search: true, className: 'exo2 f9' },
  { title: 'Department', dataIndex: 'department_name', key: 'department_name', width: '10%', search: true, className: 'exo2 f9 text-center' },
  { title: 'Status', dataIndex: 'status', key: 'status', width: '11%', render: "color-code", sort: true, className: 'exo2 f9' },
  { title: 'Classification', dataIndex: 'classification', key: 'clasification', width: '12%', render: "color-code", className: 'exo2 f9' },
  { title: 'Type', dataIndex: 'doc_type', key: 'doc_type', width: '10%', sort: true, className: 'exo2 f9 caps' },
]
const PAGE_SIZE = 15
export const AllOrgDocumentsScreen = (props) => {
  const dispatch = useDispatch()
  const [pageNo, setPageNo] = useState(1)
  const documents = useSelector(getAllOrgDocuments)
  useEffect(() => {
    dispatch(docActions.fetchAllOrgDocuments())
  }, [])
  const getDocuments = () => {
    if (documents.length > 0) {
      const startIndex = (pageNo - 1) * PAGE_SIZE;
      const endIndex = startIndex + PAGE_SIZE;
      return documents.slice(startIndex, endIndex);
    }
    return []
  }
  return (
    <div className='col w-100 h-100 o-hide screen-pad'>
      <div className='row docs-header h-btn'>
        <h4 className='exo2 f4 med'>All Org Documents</h4>
      </div>
      <div className='col f-rest doc'>
        <DocumentTable
          noSelection
          totalCount={documents.length}
          Columns={DocumentColumns}
          rows={getDocuments()}
          pageSize={PAGE_SIZE}
          onPageChange={setPageNo} />
      </div>
    </div>
  )
}