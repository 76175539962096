import "./saga";
export * from "./slice";
export * from "./helpers";

const getUserState = (state) => state.user;

export const getLogInStatus = (state) => {
  let userState = getUserState(state);
  return userState.logInStatus
}
export const getReviewers = (state) => {
  let userState = getUserState(state);
  return userState.reviewers
}
export const isLoggedOut = (state) => {
  let userState = getUserState(state);
  return userState.isLoggedOut;
}

