import React from 'react';
import { Breadcrumb } from 'antd';

export const BreadcrumbComponent = ({ isActive, isArchive, edit, create }) => {
    let items = [{
        title: (
            <a href="/A?tab=active">
                <div className='f9'><span className='c1133CC'>Active </span>  /</div>
            </a>
        )
    }];
    if (isArchive) {
        items = [{
            title: (
                <a href="/A?tab=archive">
                    <div className='f9'><span className='c1133CC'>Archive </span>  /</div>
                </a>
            )
        }];
    }
    return <Breadcrumb items={items} />;
};
export function extractContent(encodedString) {
    // Create a temporary element to decode HTML entities and remove HTML tags
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = encodedString;
    // Use textContent to get the decoded text without HTML tags
    const decodedString = tempDiv.textContent || tempDiv.innerText || '';
    return decodedString.trim();
}