import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ArcActions, getActiveOrgList, getAllocatedOrgList, getAllocationStatus, getFileUploadDetails } from '../../../../store';
import { Button, LinkButton, Menu } from "../../../../components";
import { DeleteFilled, PlusCircleOutlined, EllipsisOutlined, EditOutlined } from '@ant-design/icons';
import { Input } from 'neumetric-components';
import { AllocateModal, DeAllocateModal } from './AllocateDeallocate';
import { ArchiveDialog } from './ArchiveDialog';
import { extractContent } from './Helpers';
import { ArchiveFolderIcon } from "../../../../components"
import { SearchOutlined } from '@ant-design/icons';

const ArchiveMenu = [
    { dialogId: 'archive', label: "Archive", iconElement: <ArchiveFolderIcon />, color: 'cFE3333', font: 'f14', textClass: 'exo2 bolder', selection: true, disabled: false },
];
const UnarchiveMenu = [
    { dialogId: 'unarchive', label: "Unarchive", iconElement: <ArchiveFolderIcon />, color: 'cFE3333', selection: true },
]
export const ViewBlueprint = ({ blueprint, isArchive }) => {
    const dispatch = useDispatch();
    const orgsList = useSelector(getActiveOrgList) || [];
    const allocatedOrgs = useSelector(getAllocatedOrgList) || [];
    const allocationStatus = useSelector(getAllocationStatus)
    const fileUploadDetails = useSelector(getFileUploadDetails)
    const [state, setState] = useState({
        showMenu: null,
        showAllocateDialog: null,
        showDeAllocateDialog: null,
        showDialog: null,
        selectedOrg: null,
        searchQuery: ''
    });
    useEffect(() => {
        dispatch(ArcActions.fetchActiveOrgList());
    }, []);
    const getMenu = useCallback(() => {
        return isArchive ? UnarchiveMenu : ArchiveMenu;
    }, [isArchive]);
    const menu = getMenu();

    const handleDocsMenu = (e) => {
        setState((prevState) => ({ ...prevState, showMenu: e.target }));
    };

    const handleMenuClick = (e, menu) => {
        setState((prevState) => ({ ...prevState, showMenu: null, showDialog: menu.dialogId }));
    };

    const getOrgOptions = () => {
        let options = [];
        if (orgsList) {
            options = orgsList.customer_list && orgsList.customer_list.map((org) => {
                return { label: org[0], id: org[1] };
            });
        }
        return options;
    };

    const orgOptions = getOrgOptions();

    const handleOrgChange = (e) => {
        let value = e.target.value
        console.log({ value })
        setState((_) => ({ ..._, searchQuery: value }));
    };
    const filteredOrgs = useMemo(() => {
        return allocatedOrgs
            .filter(org => org[0].toLowerCase().includes(state.searchQuery.toLowerCase()))
            .sort((a, b) => a[0].localeCompare(b[0])); // Sort alphabetically by organization name
    }, [allocatedOrgs, state.searchQuery]);


    const isOrgAllocated = (orgId) => {
        return allocationStatus[orgId];
    };
    return (
        <>
            <div className='row header h-btn mt1'>
                <div className='f4 bold exo2 c238787'>{blueprint.blueprint_id}: {blueprint.title}</div>
                <div className='row'>
                    <LinkButton className='btn line-22 bgc1133CC exo2' label="Edit" link="edit" font='f9' iconcomponent={<EditOutlined className='suffix-icon' />} />
                    <Button className='col v-ctr h-ctr dot-menu btn-menu' iconcomponent={<EllipsisOutlined />} variant='lite' onClick={handleDocsMenu} />
                    <Menu
                        menuItems={menu}
                        width='120px'
                        anchorEl={state.showMenu}
                        onMenuClick={handleMenuClick}
                        onClose={() => handleDocsMenu({ target: null })}
                    />
                </div>
            </div>
            <div className='section-wrapper' style={{ overflowY: 'scroll' }}>
                <div className='details-section'>
                    <div className='section-header exo2'>Details</div>
                    <div className='section-body'>
                        {
                            Boolean(extractContent(blueprint.description)) &&
                            <div className='f18'>
                                <div className='exo2 bolder'>Description:</div>
                                <div className='section-padding' dangerouslySetInnerHTML={{ __html: (blueprint.description || '') }}></div>
                            </div>
                        }
                        {
                            Boolean(extractContent(blueprint.internal_notes)) &&
                            <div className='f18'>
                                <div className='exo2 bolder'>Internal Notes:</div>
                                <div className='section-padding' dangerouslySetInnerHTML={{ __html: (blueprint.internal_notes || '') }}></div>
                            </div>
                        }
                        <div>
                            <div className='f18 exo2 bolder'>Upload Information:</div>
                            <div className='f8 upload-information-wrapper'>
                                <div className='table-row'>
                                    <div className='heading-cell'>Username</div>
                                    <div className=''>{fileUploadDetails.user_name ? fileUploadDetails.user_name : ''}</div>
                                </div>
                                <div className='table-row'>
                                    <div className='heading-cell'>Filename</div>
                                    <div className=''>{fileUploadDetails.file_name ? fileUploadDetails.file_name : ''}</div>
                                </div>
                                <div className='table-row'>
                                    <div className='heading-cell'>Timestamp</div>
                                    <div className=''>{fileUploadDetails.created_at ? fileUploadDetails.created_at : ''}</div>
                                </div>
                                <div className='table-row'>
                                    <div className='heading-cell'>Task Count</div>
                                    <div className=''>{blueprint && blueprint.task_count ? blueprint.task_count : 0}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='allocation-section'>
                    <div className='section-header exo2'>Allocation</div>
                    <div className='row h-btn list-wrapper pt2'>
                        <div className='w-100'>
                            <Input
                                type='text'
                                placeholder='search Organisation'
                                onChange={handleOrgChange}
                                value={state.searchQuery}
                                suffix={<SearchOutlined className='c1133CC' />}
                                rootClassName='custom-input'
                            />
                        </div>
                    </div>
                    <div>
                        {filteredOrgs.map((org_detail, index) => {
                            return (
                                <div className='row h-btn list-wrapper' key={index}>
                                    <div className='list-item f9'>{org_detail[0]}</div>
                                    {
                                        ((isOrgAllocated(org_detail[1])) === 'allocated') ?
                                            <>
                                                <div onClick={() => {
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        showDeAllocateDialog: true,
                                                        selectedOrg: { label: org_detail[0], id: org_detail[1] }
                                                    }))
                                                }}>
                                                    <DeleteFilled style={{ color: 'red', fontSize: '14px' }} />
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div onClick={() => {
                                                    setState((prevState) => ({
                                                        ...prevState, showAllocateDialog: true,
                                                        selectedOrg: { label: org_detail[0], id: org_detail[1] }
                                                    }))
                                                }}>
                                                    <PlusCircleOutlined style={{ color: 'blue', fontSize: '14px' }} />
                                                </div>
                                            </>
                                    }
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            {
                state.showAllocateDialog &&
                <AllocateModal
                    taskCount={blueprint && blueprint.task_count ? blueprint.task_count : 0}
                    blueprint_id={blueprint && blueprint.task_blueprint_id ? blueprint.task_blueprint_id : ''}
                    title={blueprint && blueprint.title ? blueprint.title : ''}
                    selectedOrganisation={state.selectedOrg ? state.selectedOrg : {}}
                    onClose={() => { setState((prevState) => ({ ...prevState, showAllocateDialog: false })) }}
                />
            }
            {
                state.showDeAllocateDialog &&
                <DeAllocateModal
                    taskCount={blueprint && blueprint.task_count ? blueprint.task_count : 0}
                    blueprint_id={blueprint && blueprint.task_blueprint_id ? blueprint.task_blueprint_id : ''}
                    title={blueprint && blueprint.title ? blueprint.title : ''}
                    selectedOrganisation={state.selectedOrg ? state.selectedOrg : {}}
                    onClose={() => { setState((prevState) => ({ ...prevState, showDeAllocateDialog: false })) }}
                />
            }
            {
                (state.showDialog === 'archive') &&
                <ArchiveDialog
                    blueprint_id={blueprint.blueprint_id}
                    task_blueprint_id={blueprint.task_blueprint_id}
                    onClose={() => { setState((prevState) => ({ ...prevState, showDialog: false })) }}
                />
            }
            {
                (state.showDialog === 'unarchive') &&
                <ArchiveDialog
                    title={blueprint.title}
                    task_blueprint_id={blueprint.task_blueprint_id}
                    isArchive={isArchive}
                    onClose={() => { setState((prevState) => ({ ...prevState, showDialog: false })) }}
                />
            }
        </>
    );
};
