import "./saga";
export * from "./slice";

const getArcState = (state) => state.Arc;
export const ARCSTATUS = {
  ACTIVE: 'active',
  ARCHIVED: 'archived'
}
export const getTaskStatusList = (state) => {
  const _state = getArcState(state);
  return _state.StatusList
}
export const getTaskBlueprints = (status, state) => {
  const _state = getArcState(state);
  return status === 'active' ? _state.activeTaskBlueprints : status === 'archive' ? _state.archivedTaskBlueprints : null;
}
export const getActiveBlueprints = (status, state) => {
  const _state = getArcState(state);
  return _state.activeTaskBlueprints;
}
export const getArchiveBlueprints = (status, state) => {
  const _state = getArcState(state);
  return _state.archivedTaskBlueprints;
}
export const getTaskBlueprintData = (state) => {
  const _state = getArcState(state);
  return _state.blueprintData
}
export const getBlueprintStatusResponse = (state) => {
  const _state = getArcState(state);
  return _state.csvFileStatusResponse
}
export const isBlueprintArchived = (blueprint) => blueprint && (blueprint.status === 'archived' || blueprint.status === 'archive')
export const isBlueprintActive = (blueprint) => blueprint && (blueprint.status === 'active')
export const getActiveOrgList = (state) => {
  const _state = getArcState(state);
  return _state.activeOrgList
}
export const getAllocatedOrgList = (state) => {
  const _state = getArcState(state);
  return _state.allocatedOrgList
}
export const getAllocationStatus = (state) => {
  const _state = getArcState(state);
  return _state.allocationStatus
}
export const getFileUploadDetails = (state) => {
  const _state = getArcState(state);
  return _state.fileUploadDetails
}