import { put, takeEvery, select } from "redux-saga/effects";
import { SagaRegistry, setAlertAndLoading, axios, requestHandler, getDateInFormat, getUserDetails } from "../app";
import { docActions } from "./slice";
import { getDepartments, getDocClassifications, getDocTypes, dateSorter } from "../common";

const mapContent = (departments, types, classifications, _) => {
  const content = { ..._, more: 'Info', key: _.id };
  content.updated_at = getDateInFormat(content.updated_at)
  content.created_at = getDateInFormat(content.created_at)
  getUserDetails(content, 'creator');
  getUserDetails(content, 'updater');
  if (content.department_name) {
    const dept = departments.find((_) => _.id === content.department_name);
    if (dept) content.department_name = dept;
  }
  if (content.doc_type) {
    const type = types.find((_) => _.id === content.doc_type);
    if (type) {
      const propercase_type = { ...type };
      propercase_type.label = propercase_type.label.charAt(0).toUpperCase() + propercase_type.label.substr(1).toLowerCase();
      content.doc_type = propercase_type;
    }
  }
  if (content.classification) {
    const classif = classifications.find((_) => _.id === content.classification);
    if (classif) {
      const propercase_classif = { ...classif }
      propercase_classif.label = propercase_classif.label.charAt(0).toUpperCase() + propercase_classif.label.substr(1).toLowerCase();
      content.classification = propercase_classif;
    }
  }
  return content
}

const mapDocumentContent = (departments, types, classifications, _) => {
  const document = { ..._, key: _.id };
  document.ent_org_name = _.ent_org && _.ent_org.brandname
  if (document.department_name) {
    const dept = departments.find((_) => _.id === document.department_name);
    if (dept) document.department_name = dept;
  }
  if (document.doc_type) {
    const type = types.find((_) => _.id === document.doc_type);
    if (type) {
      const propercase_type = { ...type };
      propercase_type.label = propercase_type.label.charAt(0).toUpperCase() + propercase_type.label.substr(1).toLowerCase();
      document.doc_type = propercase_type;
    }
  }
  if (document.classification) {
    const classif = classifications.find((_) => _.id === document.classification);
    if (classif) {
      const propercase_classif = { ...classif }
      propercase_classif.label = propercase_classif.label.charAt(0).toUpperCase() + propercase_classif.label.substr(1).toLowerCase();
      document.classification = propercase_classif;
    }
  }
  return document
}

function* addVariable({ payload }) {
  yield requestHandler(function* () {
    const body = { variable: payload }
    yield axios.post('/centrals/create_variable', body);
    yield put(docActions.fetchAllVariables())
    yield setAlertAndLoading(null, { message: 'Variable Created Successfully' })
  }, "Failed to add Variable");
}
function* fetchAllVariables() {
  yield requestHandler(function* () {
    const StatusList = yield select((state) => state.doc.StatusList);
    const VariableSources = yield select((state) => state.doc.VariableSources);
    let res = yield axios.get('/centrals/var_list');
    const variables = (res.variable_list || []).map((variable) => {
      let _var = { ...variable }
      if (!_var.status) {
        _var.status = 'inactive'
      }
      let status = StatusList.find(_ => _.id === _var.status);
      _var.status = status || ''
      let source = VariableSources.find(_ => _.id === _var.source);
      _var.source = source || { id: _var.source, label: _var.source };
      return _var
    });
    yield put(docActions.setAllVariables(variables))
  }, "Failed to load variables");
}
function* createContentBlueprint({ payload }) {
  yield requestHandler(function* () {
    const body = { blueprint: payload.blueprint }
    yield axios.post('/centrals/create_blueprint', body);
    yield put(docActions.fetchContentBlueprints())
    yield setAlertAndLoading(null, { message: 'Content Blueprint Created.' })
  }, "Failed to Create Content Blueprint");
}
function* fetchContentBlueprints() {
  yield requestHandler(function* () {
    let res = yield axios.get('/centrals/content_bp_list');
    let active = [], draft = [], archive = [];
    const departments = yield select(getDepartments), types = yield select(getDocTypes),
      classifications = yield select(getDocClassifications);
    res = res.blueprint_list ? res.blueprint_list : res;
    res = Array.isArray(res) ? res : [];
    res.sort(dateSorter.bind(null, 'updated_at'))
    res.forEach((_, i) => {
      const content = mapContent(departments, types, classifications, _, i);
      if (content.status === 'active') {
        active.push(content)
      } else if (content.status === 'archived' || content.status === 'archive') {
        content.link = '/D/contents/' + (content.guid || content.id);
        archive.push(content)
      } else if (content.status !== 'deleted') {
        draft.push(content)
      }
    });
    yield put(docActions.setContentBlueprints({ active, draft, archive }))
  }, "Failed to Fetch Content Blueprints");
}
function* fetchContentAudit({ payload }) {
  yield requestHandler(function* () {
    const body = { blueprint_id: payload.id }
    const res = yield axios.post('/centrals/content_updated_audits', body);
    let history = (res.blueprint_histories || [])
    history.sort(dateSorter.bind(null, 'created_at'))
    history = history.map((audit, i) => {
      const _a = { ...audit, action: 'Update' }
      getUserDetails(_a, 'creator')
      _a.created_at = getDateInFormat(_a.created_at)
      _a.responsible = `${_a.username ? `${_a.username}, ` : ''}${_a.designation ? `${_a.designation}, ` : ''}${_a.department_name || ''}`;
      return { ..._a };
    });

    //audits
    let audits = (res.audits || [])
    audits = audits.filter((_) => {
      return (Boolean(_.audited_changes && Array.isArray(_.audited_changes.status)) || _.action === 'create')
    })
    audits.sort(dateSorter.bind(null, 'created_at'))
    audits = audits.map((audit, i) => {
      const _a = { ...audit }
      // getUserDetails(_a, 'creator')
      _a.designation_function = `${_a.designation ? `${_a.designation}, ` : ''}${_a.department_name || ''}`;
      _a.created_at = getDateInFormat(_a.created_at)
      _a.action = _a.action === 'create' ? _a.audited_changes.status : _a.audited_changes.status[1]
      return _a;
    });
    yield put(docActions.setBlueprintChangeHistory(history))
    yield put(docActions.setBlueprintAuditHistory(audits))
  }, "Failed to fetch History");
}
function* fetchBlueprint({ payload }) {
  yield requestHandler(function* () {
    let { id, isContent } = payload;
    let res;
    if ((typeof id) === 'string') {
      let docId
      if (isContent) {
        docId = yield axios.post('/centrals/get_blueprint_id', { guid: id })
      } else {
        docId = yield axios.post('/centrals/get_presentation_id', { guid: id })
      }
      id = docId.id
    }
    if (isContent) {
      res = yield axios.get('/centrals/show_blueprint?blueprint_id=' + id);
    } else {
      res = yield axios.get('/centrals/show_presentation?presentation_id=' + id);
    }
    const departments = yield select(getDepartments), types = yield select(getDocTypes),
      classifications = yield select(getDocClassifications);
    if (res) {
      const content = mapContent(departments, types, classifications, res)
      yield put(docActions.setShowBlueprint({ blueprint: content }))
    }
  }, "Failed to fetch Blueprint");
}
function* updateContent(body) {
  return axios.put('/centrals/update_blueprint', body);
}
function* updatePresentation(body) {
  return axios.put('/centrals/update_presentation', body)
}
function* updateBlueprint({ payload }) {
  yield requestHandler(function* () {
    let body = { blueprint_id: payload.id, blueprint: payload.blueprint }
    yield updateContent(body)
    if (payload.updateHistory) {
      body = { blueprint_id: payload.id, ...payload.updateHistory }
      yield axios.post('/centrals/add_blueprint_history', body)
    }
    yield put(docActions.fetchContentBlueprints())
    yield put(docActions.fetchBlueprint({ id: payload.id, isContent: true }))
    yield setAlertAndLoading(null, { message: 'Document has been saved.' })
  }, "Failed to fetch Blueprint");
}
function* updatePresentationBlueprint({ payload }) {
  yield requestHandler(function* () {
    yield put(docActions.setShowBlueprint({ blueprint: null }))
    let body = { presentation_id: payload.id, presentation: payload.presentation }
    yield updatePresentation(body);
    if (payload.updateHistory) {
      body = { presentation_id: payload.id, ...payload.updateHistory }
      yield axios.post('/centrals/add_doc_history', body)
    }
    yield put(docActions.fetchBlueprint({ id: payload.id }))
    yield setAlertAndLoading(null, { message: 'Document has been saved.' })
  }, "Failed to fetch Blueprint");
}
function* sendDocForReview({ payload }) {
  yield requestHandler(function* () {
    let body = { blueprint_id: payload.id, approver_id: payload.email }
    const path = payload.isContent ? 'send_content_for_approve' : 'send_presentation_for_approve';
    yield axios.post(`/centrals/${path}`, body);
    yield put(docActions.fetchBlueprint({ isContent: payload.isContent, id: payload.id }))
    yield setAlertAndLoading(null, { message: 'Document has been sent for Approval' })
  }, "Failed to send for reviewer");
}
function* archiveDocument({ payload }) {
  yield requestHandler(function* () {
    yield put(docActions.setDeleteInProgress(true))
    const { isContent, id } = payload;
    yield put(docActions.removeBlueprint(payload))
    if (isContent) {
      let body = { blueprint_id: id, blueprint: { status: 'archived' } }
      yield updateContent(body);
      yield put(docActions.fetchContentBlueprints())
    } else {
      let body = { presentation_id: id, presentation: { status: 'archived' } }
      yield updatePresentation(body)
      yield put(docActions.fetchPresentationBlueprints())
    }
    yield setAlertAndLoading(null, { message: 'Document has been Archived' })
    yield put(docActions.setDeleteInProgress(false))
  }, "Failed to archive document");
}
function* unarchiveDocument({ payload }) {
  yield requestHandler(function* () {
    const { isContent, id, status } = payload
    yield put(docActions.removeBlueprint(payload))
    if (isContent) {
      let body = { blueprint_id: id, blueprint: { status: status } }
      yield updateContent(body)
      yield put(docActions.fetchContentBlueprints())
    } else {
      let body = { presentation_id: id, presentation: { status: status } }
      yield updatePresentation(body)
      yield put(docActions.fetchPresentationBlueprints())
    }
    yield setAlertAndLoading(null, { message: 'Document has been unarchived' })
    yield put(docActions.setDeleteInProgress(false))
  }, "Failed to unarchive document");
}
function* deleteDocument({ payload }) {
  yield requestHandler(function* () {
    const { id, isContent } = payload;
    try {
      yield put(docActions.removeBlueprint(payload))
      if (isContent) {
        let body = { blueprint_id: id }
        yield axios.post('/centrals/delete_blueprint', body);
      } else {
        let body = { presentation_id: id }
        yield axios.post('/centrals/delete_presentation', body);
      }
      yield setAlertAndLoading(null, { message: 'Document has been Deleted' })
      yield put(docActions.setDeleteInProgress(false))
    } catch (error) {
      console.log(error);
      if (isContent) {
        yield put(docActions.fetchContentBlueprints())
      } else {
        yield put(docActions.fetchPresentationBlueprints())
      }
      yield put(docActions.setDeleteInProgress(false))
      throw error
    }
  }, "Failed to delete document");
}
function* createPresentationBlueprint({ payload }) {
  yield requestHandler(function* () {
    const body = { "presentation": payload.presentation }
    yield axios.post('/centrals/create_presentation', body);
    yield setAlertAndLoading(null, { message: 'Presentation Blueprint Created.' })
    yield put(docActions.fetchPresentationBlueprints())
  }, "Failed to Create presentation Blueprint");
}
const fetchNewDocId = function* ({ payload }) {
  yield requestHandler(function* () {
    const body = { "doc_id": payload }
    const res = yield axios.post('/documents/get_doc_id', body);
    yield put(docActions.setNewDocId(res.doc_id))
  }, "Failed to fetch new document Id");
}
const fetchActivePresentation = function* () {
  yield requestHandler(function* () {
    let presentation = yield axios.get('/centrals/active_presentation_list');
    presentation = presentation.blueprint_list ? presentation.blueprint_list : presentation;
    presentation = Array.isArray(presentation) ? presentation : [];
    presentation = presentation[0] || null;
    yield put(docActions.setActivePresentation(presentation))
  })
}
const fetchPresentationBlueprints = function* () {
  yield requestHandler(function* () {
    let res = yield axios.get('/centrals/presentation_list');
    let active = [], archive = [];
    const StatusList = yield select((state) => state.doc.StatusList);
    res = res.blueprint_list ? res.blueprint_list : res;
    res = Array.isArray(res) ? res : [];
    res.sort(dateSorter.bind(null, 'updated_at'))
    res.forEach((_, i) => {
      const content = mapContent([], [], [], _, i);
      const status = content.status
      const statusId = StatusList.find((_) => (_.id === content.status))
      if (statusId) content.status = statusId;
      if (status === 'archived' || status === 'archive') {
        content.link = '/D/presentations/' + (content.guid || content.id);
        archive.push(content)
      } else if (status !== 'deleted') {
        active.push(content)
      }
    })
    yield put(docActions.setPresentationBlueprints({ active, archive }))
  }, "Failed to fetch presentation blueprints");
}
const updateStatus = function* ({ payload }) {
  yield requestHandler(function* () {
    const { id, status, isContent, message } = payload
    if (isContent) {
      let body = { blueprint_id: id, blueprint: { status: status } }
      yield updateContent(body)
      yield put(docActions.fetchContentBlueprints())
    } else {
      let body = { presentation_id: id, presentation: { status: status } }
      yield updatePresentation(body)
      yield put(docActions.fetchPresentationBlueprints())
    }
    yield put(docActions.fetchBlueprint({ id: id, isContent: isContent }))
    yield setAlertAndLoading(null, { message: message || 'Document status has been updated' })
  }, "Failed to update blueprint status");
}
const fetchAllOrgDocuments = function* () {
  yield requestHandler(function* () {
    let res = yield axios.get('/documents/get_all_org_doc_for_admin');
    let list = [];
    if (Array.isArray(res) && res.length > 0) {
      const departments = yield select(getDepartments), types = yield select(getDocTypes),
        classifications = yield select(getDocClassifications);
      for (let i = 0; i < res.length; i++) {
        const _ = res[i];
        if (_.attachments && _.attachments.length === 0) {
          const doc = mapDocumentContent(departments, types, classifications, _);
          list.push(doc);
        }
      }
      yield put(docActions.setAllOrgDocuments(list))
    }
  }, "Failed to load documents");
}
const fetchOrgDocument = function* ({ payload }) {
  yield requestHandler(function* () {
    let isIDNan = isNaN(Number(payload.documentId))
    let id = isIDNan ? payload.documentId : Number(payload.documentId);
    if (isIDNan) {
      id = yield axios.post('/documents/get_document_id', { guid: id });
      if (!id.id) {
        yield put(docActions.setCurrentOrgDoc('NOTFOUND'))
        return
      }
      id = id.id
    }
    let res = yield axios.get('/documents/get_doc_for_admin?document_id=' + id)
    if (res) {
      const departments = yield select(getDepartments), types = yield select(getDocTypes),
        classifications = yield select(getDocClassifications);
      res = mapDocumentContent(departments, types, classifications, res);
      yield put(docActions.setCurrentOrgDoc(res))
    }
  }, "Failed to load documents");
}
SagaRegistry.register(function* userSaga() {
  yield takeEvery("doc/addVariable", addVariable);
  yield takeEvery("doc/fetchAllVariables", fetchAllVariables);
  yield takeEvery("doc/createContentBlueprint", createContentBlueprint);
  yield takeEvery("doc/fetchContentBlueprints", fetchContentBlueprints);
  yield takeEvery("doc/fetchPresentationBlueprints", fetchPresentationBlueprints);
  yield takeEvery("doc/fetchContentAudit", fetchContentAudit);
  yield takeEvery("doc/fetchBlueprint", fetchBlueprint);
  yield takeEvery("doc/updateBlueprint", updateBlueprint);
  yield takeEvery("doc/updatePresentation", updatePresentationBlueprint);
  yield takeEvery("doc/sendDocForReview", sendDocForReview);
  yield takeEvery("doc/archiveDocument", archiveDocument);
  yield takeEvery("doc/unarchiveDocument", unarchiveDocument);
  yield takeEvery("doc/deleteDocument", deleteDocument);
  yield takeEvery("doc/fetchNewDocId", fetchNewDocId);
  yield takeEvery("doc/updateStatus", updateStatus);
  yield takeEvery("doc/createPresentationBlueprint", createPresentationBlueprint);
  yield takeEvery("doc/fetchActivePresentation", fetchActivePresentation);
  yield takeEvery("doc/fetchAllOrgDocuments", fetchAllOrgDocuments);
  yield takeEvery("doc/fetchOrgDocument", fetchOrgDocument);
})