import React from 'react';
import { FormDialog } from "../../../../components";
import VideoPlayer from 'react-player'

export const VideoPlayerComponent = (props) => {
    const { youtube_link } = props;
    return (
        <FormDialog
            className='video-image-template'
            onClose={props.onClose}
        >
            <div className="video-container">
                {
                    youtube_link &&
                    <VideoPlayer
                        controls
                        disablePictureInPicture
                        config={{
                            file: {
                                attributes: {
                                    controlsList: "nodownload noplaybackrate",
                                    disablePictureInPicture: 'true'
                                }
                            }
                        }}
                        muted={false}
                        volume={0.1}
                        url={youtube_link}
                        poster={null}
                    />
                }
            </div>
        </FormDialog>
    )
}
export const ImageViewer = (props) => {
    const { image_url } = props
    return (
        <FormDialog
            className='learning-template'
            onClose={props.onClose}
        >
            <div className='col v-ctr oy-auto'>
                <img src={image_url} alt="Guide Image" style={{ width: '100%', height:'100%' }} />
            </div>
        </FormDialog>
    )
}