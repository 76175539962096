export const StatusMenus = [
    // { dialogId: 'status', label: "Activate", iconElement: <CheckBoxIcon style={{ fontSize: '20px' }} />, selection: true, color: 'c999', disabled: true },
]
export const ArchiveMenu = [
    { dialogId: 'archive', label: "Archive", icon: 'icon-open-folder', color: 'cFE3333', selection: true, disabled: false },
]
export const UnarchiveMenu = [
    { dialogId: 'unarchive', label: "Unarchive", icon: 'icon-open-folder', color: 'cFE3333', selection: true },
]
export const DeleteMenu = [
    { dialogId: 'delete', label: "Delete", icon: 'icon-delete', color: 'cFE3333', selection: true },
]
export const Modules = [
    { id: 'Arc', label: 'Arc' }, { id: 'Auditor', label: 'Auditor' }, { id: 'Console', label: 'Console' },
    { id: 'Continuer', label: 'Continuer' }, { id: 'Documenter', label: 'Documenter' }, { id: 'Educator', label: 'Educator' },
    { id: 'Investigator', label: 'Investigator' }, { id: 'Introduction', label: 'Introduction' }, { id: 'Mitigator', label: 'Mitigator' }, { id: 'Records', label: 'Records' }
]
export const Fields = [
    { label: 'Module:', attribute: 'module_name', placeholder: "Module", type: 'select', required: true, labelFontSize: 'f7', labelClassName: 'w-170pix', className: 'w-320pix', labeltextclassname: 'exo2' },
]
export const SendForReview = [
    { label: 'Reviewer', attribute: 'reviewer_email', type: 'select', placeholder: 'type Reviewer Name...', required: true, showColon: true, className: 'cdcdcdc', labelClassName: 'w-90pix', searchIcon: true, hideIcon: true }
]