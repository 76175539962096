import { put, takeEvery } from "redux-saga/effects";
import { SagaRegistry, requestHandler, axios } from "../app";
import { commonActions } from "./slice";
import { dateSorter } from "./index";

function* fetchDepartments() {
  yield requestHandler(function* () {
    let res = yield axios.get('/consoles/department_list');
    res = (res.department_list || []).map((_) => ({ label: _, id: _ }));
    yield put(commonActions.setDepartments(res))
  }, "Failed to Fetch Departments", false);
}
function* fetchDocumentTypes() {
  yield requestHandler(function* () {
    let res = yield axios.get('/centrals/doc_type_list');
    res = Object.keys(res.types || {}).map((_) => ({ label: _, attribute: _, id: _ }));
    yield put(commonActions.setDocumentTypes(res))
  }, "Failed to Fetch Types", false);
}
function* fetchDocumentClassifications() {
  yield requestHandler(function* () {
    let res = yield axios.get('/centrals/doc_classi_list');
    let types = (res.types || {}), temp = Object.keys(types), list = [];
    for (let i = 0; i < temp.length; i++) {
      let cls = temp[i]
      list[types[cls]] = { label: cls, attribute: cls, id: cls }
    }
    yield put(commonActions.setDocumentClassifications(list))
  }, "Failed to Fetch Classification", false);

}
function* fetchOrgs() {
  yield requestHandler(function* () {
    let res = yield axios.get('/consoles/org_list');
    res.active_orgs.sort(dateSorter.bind(null, 'created_at'))
    res.disable_orgs.sort(dateSorter.bind(null, 'updated_at'))
    console.log({res})
    yield put(commonActions.fetchOrgSuccess(res));
  });
}
SagaRegistry.register(function* userSaga() {
  yield takeEvery("common/fetchDepartments", fetchDepartments);
  yield takeEvery("common/fetchDocumentTypes", fetchDocumentTypes);
  yield takeEvery("common/fetchDocumentClassifications", fetchDocumentClassifications);
  yield takeEvery("common/fetchOrgs", fetchOrgs);
})